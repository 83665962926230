<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4">
        <v-card hover class="mx-auto" max-width="400">
          <v-img
            class="white--text align-end"
            src="/images/LegendaryEggT1.PNG"
          ></v-img>
        </v-card>
      </v-col>
    </v-row>
    <Egg1BackButton route="LegendaryLanding" />
  </v-container>
</template>
<script>
import Egg1BackButton from '@/components/Egg1BackButton.vue'

export default {
  components: { Egg1BackButton },
}
</script>

<style></style>
